.upgrade-notification {
  border-radius: 0 !important;
}

.upgrade-notification-header {
  margin: 1.25rem;
}

.upsell-warning {
  background-color: $danger-100;
}

.upsell-warning-light {
  background-color: $warning-100;
}

.upsell-warning, .upsell-warning-light {
  padding: 0.5rem 1.25rem;
}

// .fa-ul added so specificity is higher than Font Awesome's .fa-ul.
// An additional Font Awesome stylesheet is imported by Braze in
// stage/production but not devstack.
.upgrade-notification-ul.fa-ul {
  padding-left: 1.25rem;
  padding-top: 0.875rem;
  padding-right: 1.25rem;
}

.upgrade-notification-text {
  padding: 0.875rem 1.25rem 0 1.25rem;
}

.upgrade-notification-button {
  padding: 1.25rem;
  padding-top: 0;
}

.discount-info {
  border-top: 1px solid $light-400;
  padding-top: .75rem;
  padding-bottom: .75rem;
}
