.alert-content.lock-paywall-container {
    display: inline-flex;
    width: 100%;
}

.lock-paywall-container svg {
    color: $primary-700;
}

@media only screen and (min-width: 992px) and (max-width: 1100px) {
    .list-div {
        width: 62%;
    }
}
