.upsell-bullet > .fa-li {
    left: -31px;
    padding-right: 22px;
}

.inline-link-underline {
  text-decoration: underline;
}

.upsell-bullet a {
  color: $primary-500;
}

